<template>
  <div class="box">
    <div class="title">{{ $t("index.为探索而生") }}</div>
    <ul class="smalltitle">
      <li>
        <p>
          <img src="../../../assets/imgs/ForExplore01.png" />
        </p>
        <p class="headline">{{ $t("index.零售中心") }}</p>
        <p class="content">
          {{ $t("index.找到距离您最近的格物销售和服务中心") }}
        </p>
      </li>
      <li>
        <p>
          <img src="../../../assets/imgs/ForExplore02.png" />
        </p>
        <p class="headline">{{ $t("index.金融计算器") }}</p>
        <p class="content">{{ $t("index.多种贷款方式，总有一款适合您") }}</p>
      </li>
      <li>
        <p>
          <img src="../../../assets/imgs/ForExplore03.png" />
        </p>
        <p class="headline">{{ $t("index.参数配置") }}</p>
        <p class="content">
          {{ $t("index.查看参数配置，选择更适合您的摩托车") }}
        </p>
      </li>
    </ul>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang='scss' scoped>
@import "~@assets/styles/func.scss";
img {
  vertical-align: top;
}
.box {
  width: 100vw;
  text-align: center;
  .title {
    font-family: PingFang-SC-Medium;
    font-size: px2rem(40px);
    line-height: px2rem(100px);
  }
  .smalltitle {
    width: 100%;
    padding: px2rem(30px) 0;
    li {
      margin: 8vw 0;
      font-size: px2rem(18px);
      p {
        text-align: left;
        width: 100vw;
        img {
          width: 100%;
        }
      }
      .headline {
        font-family: PingFang-SC-Bold;
        font-weight: bold;
        font-size: px2rem(24px);
        line-height: px2rem(40px);
        margin-top: px2rem(5px);
        margin-left: px2rem(24px);
      }
      .content {
        font-family: PingFang-SC-Regular;
        font-weight: regular;
        font-size: px2rem(20px);
        margin-left: px2rem(24px);
      }
    }
    li:hover {
      cursor: pointer;
    }
  }
}
</style>