<template>
  <div class="wrap">
    <!-- <div class="top"></div> -->
    <div class="slide">
      <video
        :src="'https://cdn.gowow.cn/video/index-video-0' + num + '.mp4'"
        autoplay
        poster="../assets/imgs/homevideo1.jpg"
        loop
        muted
      ></video>
    </div>
    <div class="content">
      <div class="section">
        <div>GOWOW ORI</div>
      </div>
      <div class="section">
        <template>
          <!-- <div>{{ $t("index.格物") }}</div> -->
          <div>{{ $t("index.首款全地形新能源摩托车 猎户座") }}</div>
          <!-- <div class="tip">
            {{ $t("index.全国统一零售价35,700RMB,现已开启预约试驾与订购服务") }}
          </div> -->
        </template>
      </div>
      <div style="display: flex; margin-left: 80px">
        <div class="see-video-btn" @click="product">
          {{ $t("index.了解详情") }}
        </div>
        <div class="see-video-btn" @click="gotrial">
          {{ $t("index.预约试驾") }}
        </div>
      </div>
      <br />
      <div style="display: flex; margin-left: 100px" class="progressBox">
        <div @click="fn1">
          <el-progress
            :percentage="progress1"
            :show-text="false"
            color="gray"
            :stroke-width="3"
          ></el-progress>
        </div>
        <div @click="fn2">
          <el-progress
            :percentage="progress2"
            :show-text="false"
            color="gray"
            :stroke-width="3"
          ></el-progress>
        </div>
      </div>
    </div>
    <!-- <div class="bottom"></div> -->
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import { mapState } from "vuex";
export default {
  data() {
    return {
      num: 1,
      progress1: 0,
      progress2: 0,
      item1: "",
      item2: "",
      videoitem: "",
    };
  },
  computed: {
    ...mapState(["lang"]),
    isZhCN() {
      return this.lang === "zh-CN";
    },
  },
  mounted() {
    this.video();
    this.gress1();
  },
  methods: {
    gotrial() {
      goto("/trial");
    },
    product() {
      goto("/product/ORI");
    },
    // seeVideo() {
    //   let url = "https://cdn.gowow.cn/video/gowow-cn-1080.mp4";
    //   if (!this.isZhCN) {
    //     url = "https://cdn.gowow.cn/video/gowow-en-1080.mp4";
    //   }
    //   window.open(url, "_black");
    // },
    video() {
      this.videoitem = setInterval(() => {
        this.num++;
        if (this.num > 2) {
          this.num = 1;
        }
      }, 6000);
    },
    gress1() {
      this.progress2 = 0;
      this.item1 = setInterval(() => {
        this.progress1++;
      }, 60);
    },
    gress2() {
      this.progress1 = 0;
      this.item2 = setInterval(() => {
        this.progress2++;
      }, 60);
    },
    fn1() {
      this.num = 1;
      clearInterval(this.videoitem);
      this.video();
    },
    fn2() {
      this.num = 2;
      clearInterval(this.videoitem);
      this.video();
    },
  },
  watch: {
    num: function (newV) {
      // console.log(newV);
      if (newV === 1) {
        clearInterval(this.item2);
        this.gress1();
      }
      if (newV === 2) {
        clearInterval(this.item1);
        this.gress2();
        console.log();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

// pc端
@media (min-width: $screen-md-min) {
  .flex-column-alignCenter {
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding-left: 100px;
  }
  ::v-deep .el-progress-bar {
    width: 30px;
    margin-left: 8px;
  }

  :hover ::v-deep .el-progress-bar {
    cursor: pointer;
  }
  img {
    vertical-align: top;
  }
  .wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    .slide {
      width: 100vw;
      video {
        width: 100%;
        vertical-align: top;
      }
    }
    .content {
      position: absolute;
      top: 40%;
      z-index: 2;
      color: #ffffff;
      text-shadow: 0px 2px 15px 0px rgba(86, 84, 84, 1);
      @extend .flex-column-alignCenter;
      justify-content: center;

      .section {
        @extend .flex-column-alignCenter;
        text-shadow: 0px 2px 15px #565454;
        &:nth-of-type(1) {
          font-family: PingFang-SC-Medium;
          font-size: 50px;
          line-height: 30px;
        }
        &:nth-of-type(2) {
          margin-top: 30px;
          font-family: PingFang-SC-Medium;
          font-size: 25px;
          line-height: 30px;
          font-weight: 200;
        }
      }
      .see-video-btn {
        cursor: pointer;
        margin-top: 20px;
        margin-left: 20px;
        @include flex-center("row");
        width: 170px;
        height: 40px;
        border: 2px solid rgba(255, 255, 255, 1);
        // box-shadow: 0px 2px 12px 0px rgba(154, 154, 154, 0.5);
        font-size: 16px;
        font-weight: 200;

        font-family: PingFang-SC-Medium;
      }
    }
  }
}
// m端
// @media (max-width: $screen-md-min) {
//   .flex-column-alignLeft {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//   }
//   ::v-deep .el-progress-bar {
//     width: 30px;
//     margin: 5px;
//   }
//   :hover ::v-deep .el-progress-bar {
//     cursor: pointer;
//   }
//   .wrap {
//     position: relative;
//     .slide {
//       position: absolute;
//       z-index: 1;
//       left: 0;
//       top: 0;
//       height: 100%;
//       width: 100%;
//       // background: url("~@assets/imgs/slide1.png") no-repeat center/cover;
//     }
//     .content {
//       position: relative;
//       z-index: 2;
//       padding-left: px2rem(28px);
//       color: #ffffff;
//       text-shadow: 0 px2rem(2px) px2rem(15px) 0 rgba(86, 84, 84, 1);
//       @extend .flex-column-alignLeft;
//       .section {
//         @extend .flex-column-alignLeft;
//         text-shadow: 0px px2rem(2px) px2rem(15px) #565454;
//         &:nth-of-type(1) {
//           justify-content: flex-start;
//           margin-top: px2rem(21px);
//           font-family: PingFang-SC-Medium;
//           font-size: px2rem(25px);
//           line-height: px2rem(30px);
//         }
//         &:nth-of-type(2) {
//           margin-top: px2rem(85px);
//           font-family: PingFang-SC-Medium;
//           font-size: px2rem(16px);
//           line-height: px2rem(28px);
//           .tip {
//             line-height: px2rem(12px);
//             font-size: px2rem(8px);
//           }
//           .slide-line {
//             width: px2rem(40px);
//             height: px2rem(2px);
//             background: #ffffff;
//             margin: px2rem(15px) 0;
//           }
//         }
//       }
//       .see-video-btn {
//         margin-top: px2rem(31px);
//         margin-bottom: px2rem(34px);
//         @include flex-center("row");
//         width: px2rem(97px);
//         height: px2rem(31px);
//         border: 1px solid rgba(255, 255, 255, 1);
//         box-shadow: px2rem(0px) px2rem(2px) px2rem(15px) 0 rgba(86, 84, 84, 1);
//         font-size: px2rem(12px);
//         font-family: PingFang-SC-Medium;
//         line-height: px2rem(20px);
//       }
//     }
//   }
// }
</style>
