<template>
  <div :class="['wrap', { ispc: platform.isPC }]" v-show="lang">
    <Header />
    <Slide1 class="slide1" v-if="platform.isPC" />
    <Slide1M v-else />
    <KnowOri v-if="platform.isPC" />
    <KnowOriM v-else />
    <ForExplore v-if="platform.isPC" />
    <ForExploreM v-else />
    <div v-if="isZhCN">
      <TrialApplication v-if="platform.isPC" />
      <TrialApplicationM v-else />
    </div>

    <DownLoad v-if="platform.isPC" />
    <DownLoadM v-else />

    <Footer />
    <!-- <FloatBall /> -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Slide1 from "@/components/Slide1";
import Slide1M from "@/components/Slide1M";
import KnowOri from "./components/KnowOri";
import KnowOriM from "./components/KnowOriM";
import ForExplore from "./components/ForExplore";
import ForExploreM from "./components/ForExploreM";
import DownLoad from "./components/DownLoad";
import DownLoadM from "./components/DownLoadM";
import TrialApplication from "../trial/components/TrialApplication";
import TrialApplicationM from "../trial/components/TrialApplicationM";
import { mapState } from "vuex";
import platform from "@utils/platform";
export default {
  name: "App",
  components: {
    Header,
    Slide1,
    Slide1M,
    Footer,
    KnowOri,
    KnowOriM,
    ForExplore,
    ForExploreM,
    DownLoad,
    DownLoadM,
    TrialApplication,
    TrialApplicationM,
  },
  computed: {
    ...mapState(["lang"]),
    isZhCN() {
      const isCN = this.lang === "zh-CN";
      if (!isCN) {
        document.title = "GOWOW";
      }
      return isCN;
    },
  },
  data() {
    return {
      platform,
    };
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/util.scss";
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
* {
  margin: 0;
  padding: 0;
}
.wrap {
  width: 100vw;
  height: auto;
  overflow: hidden;
  @extend .iphoneX;
  &.ispc {
    display: flex;
    flex-direction: column;
    .slide1 {
      flex: 1;
    }
  }
}
@media (max-width: $screen-md-min) {
  .wrap {
    background: #ececec;
  }
}
</style>
