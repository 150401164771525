<template>
  <div class="wrap">
    <div class="bgimg">
      <img src="@assets/imgs/homeM/ORI.png" />
    </div>
    <div class="box">
      <div class="title">
        <p>{{ $t("index.猎户座") }}</p>
        <p>{{ $t("index.全地形电动摩托车") }}</p>
      </div>
      <ul class="smalltitle">
        <div class="top">
          <li class="active" @click="product">
            {{ $t("index.了解") }}{{ $t("index.猎户座") }}
          </li>
          <li @click="gotrial">
            {{ $t("index.预约试驾") }}{{ $t("index.猎户座") }}
          </li>
        </div>
        <li @click="add" class="bottom">
          {{ $t("index.定购") }}{{ $t("index.猎户座") }}
        </li>
      </ul>
    </div>
    <Float ref="Float" />
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import Float from "../../product/ORI/components/Float";

export default {
  components: {
    Float,
  },
  methods: {
    gotrial() {
      goto("/trial");
    },
    add() {
      this.$refs.Float.onBtnClick();
    },
    product() {
      goto("/product/ORI");
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/func.scss";

.wrap {
  position: relative;
  width: 100vw;
  .bgimg {
    img {
      width: 100%;
      vertical-align: top;
    }
  }
  .box {
    position: absolute;
    top: 0;
    width: 100vw;
    text-align: center;
    .title {
      margin-top: px2rem(10px);
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      font-size: px2rem(25px);
      line-height: px2rem(30px);
    }
    .smalltitle {
      width: 100%;
      justify-content: center;
      .bottom {
        margin: 0 auto;
      }
      .top {
        display: flex;
        justify-content: center;
        li {
          margin: px2rem(8px) px2rem(8px);
        }
      }
      li {
        margin: px2rem(8px) 0;
        font-size: px2rem(14px);
        line-height: px2rem(36px);
        border: 1px solid #000000;
        border-radius: px2rem(18px);
        width: 40vw;
      }
      li:hover {
        cursor: pointer;
      }
      .active {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
}
</style>